import React, { Dispatch } from 'react';
import FormComponent from '../../components/form-field/form-field';
import { withFormik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import {
    clearViewAction,
    getFilesActionSuccess,
    getIndustryActivitiesAction,
    getStatesAction,
    resetQuoteSuccessAction,
    updateQuoteAction,
} from '../../modules/quotes';
import moment from 'moment';
import WarningModal from '../../components/modal/warning-modal';
import { IReduxState } from '../../modules/store';
import { ISearchInsuredResponse } from '../../interface/quotes/searchInsured';
import { backTo, Edit } from '../../assets/icons';
import { IIndustryActivitiesData } from '../../interface/quotes/industryActivities';
import { IStates } from '../../interface/quotes/states';
import { IViewQuote, IViewQuoteById } from '../../interface/quotes/viewQuote';
import { IUpdateQuoteRequest } from '../../interface/quotes/updateQuotes';
import { IBrokerDetails } from '../../interface/quotes/getBrokerDetails';
import Banner from '../../components/banner/banner';
import { disclaimerText } from '../../constants/disclaimer';
import HelpEmail from '../help-email/help-email';
import CustomizedAccordions from '../../components/accordian/accordian';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { radiovalues, securityRequirements } from '../../constants/securityRequirements';
import { ISecurityReq } from '../../interface/common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { errorMessages, piiLimit } from '../../constants/errorMessages';
import { requestedLimits } from '../../constants/requestedLimits';
import { currencyFormat } from '../../utils/currencyFormat';

interface IViewEditQuoteProps {
    backToQuotesClick: () => void;
    resetQuote: () => void;
    insuredDetails?: ISearchInsuredResponse;
    getIndustryActivities: () => void;
    industryActivities?: IIndustryActivitiesData;
    getStates: () => void;
    states?: IStates;
    isView?: any;
    viewQuoteDetails: IViewQuote;
    clearView: () => void;
    updateQuote: (data: IUpdateQuoteRequest) => void;
    onCreateQuoteSuccess?: boolean;
    updateId?: any;
    backToViewPage: (value: IViewQuoteById) => void;
    brokerDetails?: IBrokerDetails;
    files?: any;
    getFilesSuccess: (data: any) => void;
}
export interface IViewEditQuoteFormValues {
    insuredName: string;
    website: string;
    hqStreet: string;
    hqCity: string;
    hqState: string;
    hqZip: string;
    grossRevenue: string;
    limitValue: string;
    effectiveDate: string | null;
    hqCountry: string;
    endorsements__c: string;
    deductible__c: number | string;
    premium: string;
    totalFees: number | string;
    cyberCrimeSublimit: string;
    reputationalHarmLimit: string;
    businessActivities: string;
    assignedUnderwriterName: string;
    assignedUnderwriterEmail: string;
    dateQuoted: string | null;
    estimatedNumberOfEmployees: string;
    brokerCommission: number | string;
    Subjectivities: string;
    totalPayable: number | string;
    totalSurplus: number | string;
    industryCode: string;
    mediaLiability: string;
    brokerName: string;
    emailFilteringSolution: string;
    MFA_on_Remote_Network_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_on_Email_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_for_Privileged_Accounts: 'Yes' | 'No' | 'Unknown' | null;
    Offline_backups: 'Yes' | 'No' | 'Unknown' | null;
    EDR_Fully_Deployed: 'Yes' | 'No' | 'Unknown' | null;
    Network_Monitoring: 'Yes' | 'No' | 'Unknown' | null;
    Annual_Phishing_Training: 'Yes' | 'No' | 'Unknown' | null;
    Email_Filtering: 'Yes' | 'No' | 'Unknown' | null;
    Update_Critical_Patches: 'Yes' | 'No' | 'Unknown' | null;
    Anti_virus_Software_and_Firewalls: 'Yes' | 'No' | 'Unknown' | null;
    Daily_Backups_Critical_Data: 'Yes' | 'No' | 'Unknown' | null;
    Incident_Greater_than_10k: 'Yes' | 'No' | 'Unknown' | null;
    Legal_Action: 'Yes' | 'No' | 'Unknown' | null;
    Regulatory_Action: 'Yes' | 'No' | 'Unknown' | null;
    Cannabis_or_Crypto: 'Yes' | 'No' | 'Unknown' | null;
    Penetration_Test_Last_24_mo: 'Yes' | 'No' | 'Unknown' | null;
    Aerospace_or_Defense_Ind: 'Yes' | 'No' | 'Unknown' | null;
    Grantmaking_or_giving_foundation: 'Yes' | 'No' | 'Unknown' | null;
    Provide_Technology_Services: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Change: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Request: 'Yes' | 'No' | 'Unknown' | null;
    Estimated_Number_of_PII: number | null;
    EOL_or_EOS_Software: 'Yes' | 'No' | 'Unknown' | null;
}
const ViewEditForm = (props: IViewEditQuoteProps & FormikProps<IViewEditQuoteFormValues>) => {
    const {
        handleChange,
        values,
        setFieldValue,
        backToQuotesClick,
        resetQuote,
        getIndustryActivities,
        getStates,
        handleSubmit,
        isView,
        viewQuoteDetails,
        clearView,
        onCreateQuoteSuccess,
        touched,
        errors,
        updateId,
        backToViewPage,
        setValues,
        initialValues,
        files,
        getFilesSuccess,
    } = props;
    const { classes } = useStyles();
    const [successModalStatus, setSuccessModalStatus] = React.useState(false);
    const [options, setOptions] = React.useState<any[]>([]);
    const [grossRevenueError, setGrossRevenueError] = React.useState('');
    const [isEdit, setIsEdit] = React.useState<boolean>(isView.isView);
    const [isGRFormatted, setIsGRFormatted] = React.useState(false);

    const editFromView = () => {
        setIsEdit(false);
    };
    const selectStatusBackgroundColor = (status?: string) => {
        switch (status) {
            case 'Active':
                return classes.viewActiveDiv;
            case 'Inactive':
                return classes.viewInactiveDiv;
            case 'declined':
                return classes.viewDeclinedDiv;
            case 'Pending':
                return classes.viewPendingDiv;
        }
    };
    const grossRevenueValueFormat = (value: any) => {
        setIsGRFormatted(true);
        const pattern = /^[0-9]+$/;
        if (!String(value).match(pattern)) {
            setFieldValue('grossRevenue', '$0.00');
        } else {
            const amount = currencyFormat(value, 2, 2);
            setFieldValue('grossRevenue', amount);
        }
    };
    const focusChange = (e: any) => {
        setIsGRFormatted(false);
        if (e.target.value) {
            const newValue = e.target.value.replace('$', '').replaceAll(',', '');
            const formatedGrossValue = Number(newValue);
            setFieldValue('grossRevenue', formatedGrossValue);
        }
    };
    const grossRevenueValueChange = (e:any) =>{
            const value = e.target.value;
            const pattern = /^[0-9]+$/;
        if (!isGRFormatted && value &&
    !String(value).match(pattern)){
            setGrossRevenueError(errorMessages().specialCharacterValidation);
        } else {
            setGrossRevenueError('');
        }
    };
    React.useEffect(() => {
        getIndustryActivities();
        getStates();
        return () => {
            getFilesSuccess([]);
        };
    }, []);
    React.useEffect(() => {
        if (onCreateQuoteSuccess) {
            setSuccessModalStatus(true);
        }
    }, [onCreateQuoteSuccess]);
    React.useEffect(() => {
        const checkedValues = [];
        checkedValues.push(
            viewQuoteDetails?.records[0].evO_Limit_1__c?.toString(),
            viewQuoteDetails?.records[0].evO_Limit_2__c?.toString(),
            viewQuoteDetails?.records[0].evO_Limit_3__c?.toString(),
            viewQuoteDetails?.records[0].evO_Limit_4__c?.toString()
        );
        const filteredCheckedValues = checkedValues.filter((item) => {
            return item != null;
        });
        setOptions(filteredCheckedValues);
    }, [viewQuoteDetails]);
    React.useEffect(() => {
        setFieldValue('limits', options);
    }, [options]);

    const insuredDetails = (
        <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured Business Name"
                    id="insuredName"
                    placeholder="Insured's Name"
                    className={classes.inputField1}
                    value={values.insuredName}
                    name="insuredName"
                    title={values.insuredName}
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured Street"
                    id="hqStreet"
                    placeholder="Insured Street"
                    className={classes.inputField}
                    value={values.hqStreet}
                    name="hqStreet"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured City"
                    id="hqCity"
                    placeholder="Insured City"
                    className={classes.inputField}
                    value={values.hqCity}
                    name="hqCity"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured State"
                    id="hqState"
                    placeholder="Insured State"
                    className={classes.inputField}
                    value={values.hqState}
                    name="hqState"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured Zip/Postal Code"
                    id="hqZip"
                    placeholder="Insured Zip/Postal Code"
                    className={classes.inputField}
                    value={values.hqZip}
                    name="hqZip"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Insured Country"
                    id="hqCountry"
                    placeholder="Insured Country"
                    className={classes.inputField}
                    value={values.hqCountry}
                    name="hqCountry"
                    touched={touched.hqCountry}
                    disabled
                    isReadOnly
                />
            </Grid>
        </Grid>
    );
    const insuredBusinessInformation = (
        <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Website"
                    id="website"
                    placeholder="Website"
                    className={classes.inputField}
                    value={values.website}
                    name="website"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Business Activities"
                    id="businessActivities"
                    placeholder="Business Activities"
                    className={classes.inputField}
                    value={values.businessActivities}
                    name="businessActivities"
                    touched={touched.businessActivities}
                    errors={errors.businessActivities}
                    required={!isEdit}
                    disabled={isEdit}
                    isReadOnly={isEdit}
                    datatestid="businessActivities"
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={(e)=>{
                        handleChange(e);
                        grossRevenueValueChange(e);
                    }}
                    label="Gross Annual Revenue"
                    id="grossRevenue"
                    placeholder="Gross Annual Revenue"
                    className={classes.inputField}
                    value={values.grossRevenue}
                    name="grossRevenue"
                    onBlur={() => {
                        grossRevenueValueFormat(values.grossRevenue);
                    }}
                    focusChange={focusChange}
                    touched={touched.grossRevenue}
                    errors={errors.grossRevenue}
                    required={!isEdit}
                    disabled={isEdit}
                    isReadOnly={isEdit}
                    datatestid="GrossAnnualRevenue"
                />
            <div className={classes.errorMessage1}>
                {!isGRFormatted && grossRevenueError}
            </div>
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Industry Code"
                    id="Industry__c"
                    placeholder="Industry Code"
                    className={classes.inputField}
                    value={values.industryCode}
                    name="Industry__c"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Assigned Underwriter Name"
                    id="assignedUnderwriterName"
                    placeholder="Assigned Underwriter Name"
                    className={classes.inputField}
                    value={values.assignedUnderwriterName}
                    name="assignedUnderwriterName"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Assigned Underwriter Email"
                    id="assignedUnderwriterEmail"
                    placeholder="Assigned Underwriter Email"
                    className={classes.inputField}
                    value={values.assignedUnderwriterEmail}
                    name="assignedUnderwriterEmail"
                    disabled
                    isReadOnly
                />
            </Grid>
        </Grid>
    );
    const coverageDetails = (
        <Grid container xs={12} rowSpacing={2} columnSpacing={3}>
            <Grid item xs={4}>
                <FormComponent
                    component="textarea"
                    handleChange={handleChange}
                    label="Endorsements"
                    id="endorsements__c"
                    placeholder="Endorsements"
                    className={classes.textAreaField}
                    value={values.endorsements__c}
                    name="endorsements__c"
                    isReadOnly
                    rows={3}
                    rowsMax={3}
                />
            </Grid>
            {isEdit && (
                <Grid item xs={4}>
                    <FormComponent
                        component="input"
                        label="Limit"
                        handleChange={(e) => {
                            handleChange(e);
                        }}
                        id="limitValue"
                        className={classes.inputField}
                        value={currencyFormat(Number(values.limitValue) || 0, 2, 2)}
                        name="limitValue"
                        placeholder="Limit"
                        required={!isEdit}
                        disabled={isEdit}
                        isReadOnly={isEdit}
                        datatestid="limit"
                    />
                </Grid>
            )}
            {!isEdit && (
                <Grid item xs={4}>
                    <FormComponent
                        component="select"
                        label="Limit"
                        handleChange={(e) => {
                            handleChange(e);
                        }}
                        id="limitValue"
                        className={classes.inputField}
                        value={values.limitValue}
                        name="limitValue"
                        placeholder="-Select-"
                        selectOptions={requestedLimits.map((item) => ({
                            id: item.value,
                            name: item.name,
                        }))}
                        required={!isEdit}
                        disabled={isEdit}
                        isReadOnly={isEdit}
                        datatestid="limitValue"
                    />
                </Grid>
            )}
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Deductible"
                    id="deductible__c"
                    placeholder="Deductible Bound"
                    className={classes.inputField}
                    value={values.deductible__c}
                    name="deductible__c"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Premium"
                    id="premium"
                    placeholder="Premium"
                    className={classes.inputField}
                    value={values.premium}
                    name="premium"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Total Fees"
                    id="totalFees"
                    placeholder="Total Fees"
                    className={classes.inputField}
                    value={values.totalFees}
                    name="totalFees"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Total Surplus Lines, Taxes & Fees"
                    id="totalSurplus"
                    className={classes.inputField}
                    value={values.totalSurplus}
                    name="totalSurplus"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Cyber Crime Limit"
                    labelDescription="(Subject to Underwriter Confirmation)"
                    id="cyberCrimeSublimit"
                    placeholder="Cyber Crime Limit"
                    className={classes.inputField}
                    value={values.cyberCrimeSublimit}
                    name="cyberCrimeSublimit"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Reputational Harm Limit"
                    labelDescription="(Subject to Underwriter Confirmation)"
                    id="reputationalHarmLimit"
                    placeholder="Reputational Harm Limit"
                    className={classes.inputField}
                    value={values.reputationalHarmLimit}
                    name="reputationalHarmLimit"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Media Liability"
                    labelDescription="(Subject to Underwriter Confirmation)"
                    id="mediaLiability"
                    placeholder="Media Liability"
                    className={classes.inputField}
                    value={values.mediaLiability}
                    name="mediaLiability"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component={isEdit ? 'input' : 'date'}
                    handleChange={handleChange}
                    label="Effective Date"
                    id="effectiveDate"
                    placeholder="Effective Date"
                    className={isEdit ? classes.inputField : classes.datePicker}
                    value={values.effectiveDate}
                    name="effectiveDate"
                    touched={touched.effectiveDate}
                    errors={errors.effectiveDate}
                    required={!isEdit}
                    disabled={isEdit}
                    isReadOnly={isEdit}
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Date Quoted"
                    id="dateQuoted"
                    placeholder="Date Quoted"
                    className={classes.inputField}
                    value={values.dateQuoted}
                    name="dateQuoted"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Estimated Number of Employees"
                    id="estimatedNumberOfEmployees"
                    placeholder="Estimated Number of Employees"
                    className={classes.inputField}
                    value={values.estimatedNumberOfEmployees}
                    name="estimatedNumberOfEmployees"
                    touched={touched.estimatedNumberOfEmployees}
                    errors={errors.estimatedNumberOfEmployees}
                    required={!isEdit}
                    disabled={isEdit}
                    isReadOnly={isEdit}
                    datatestid="employees"
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Broker Commission"
                    id="brokerCommission"
                    placeholder="Broker Commission"
                    className={classes.inputField}
                    value={values.brokerCommission}
                    name="brokerCommission"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Total Payable"
                    id="totalPayable"
                    placeholder="Total Payable"
                    className={classes.inputField}
                    value={values.totalPayable}
                    name="totalPayable"
                    disabled
                    isReadOnly
                />
            </Grid>
            <Grid item xs={4}>
                <FormComponent
                    component="input"
                    handleChange={handleChange}
                    label="Subjectivities"
                    id="subjectivities"
                    placeholder="Subjectivities"
                    className={classes.inputField}
                    value={values.Subjectivities}
                    name="Subjectivities"
                    disabled
                    isReadOnly
                />
            </Grid>
        </Grid>
    );
    const securityRequirementsSection = (
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.securityMainContainer}>
            <Grid item>
                {securityRequirements.map((req: ISecurityReq, index) => (
                    <Grid container xs={11} spacing={2} key={index} className={classes.securityContainerView}>
                        <Grid item xs={8}>
                            {req.label}
                            {req?.tooltipContent && (
                                <Tooltip title={req.tooltipContent} placement="top-start">
                                    <IconButton className={classes.tooltip}>
                                        <InfoOutlinedIcon className={classes.tooltipIcon} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            {req.valueLabel === 'Estimated_Number_of_PII' ? (
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    id="Estimated_Number_of_PII"
                                    placeholder="Estimated Number of PII"
                                    className={classes.inputField}
                                    touched={touched.Estimated_Number_of_PII}
                                    errors={errors.Estimated_Number_of_PII}
                                    value={values.Estimated_Number_of_PII}
                                    name="Estimated_Number_of_PII"
                                    disabled={isEdit}
                                    isReadOnly={isEdit}
                                    datatestid="pii"
                                />
                            ) : (
                                <FormComponent
                                    required
                                    value={(values as any)[req.valueLabel]}
                                    name={req.valueLabel}
                                    component="radio"
                                    id={req.valueLabel}
                                    handleChange={(e) => {
                                        handleChange(e);
                                    }}
                                    radioOptions={radiovalues}
                                    disabled={isEdit}
                                    isReadOnly={isEdit}
                                />
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
    return (
        <>
            <Banner text={disclaimerText} />
            <Grid container direction="row" xs={12} className={classes.container}>
                <Grid item xs={7} className={classes.backToQuotesDiv}>
                    <img src={backTo} />
                    <Button
                        className={classes.backToQuotesButton}
                        onClick={() => {
                            backToQuotesClick(), clearView();
                        }}
                        role="butn"
                    >
                        Back to quotes
                    </Button>
                </Grid>
                <Grid xs={2}>
                    <HelpEmail
                        emailDetails={{
                            brokerName:
                                (viewQuoteDetails?.records[0].vrnA__Broker__r.broker_Code__c ||
                                    viewQuoteDetails?.records[0].vrnA__Broker__c ||
                                    '') +
                                    ': ' +
                                    viewQuoteDetails?.records[0]?.vrnA__Broker__r.name || '',
                            subject: `Quote: ${viewQuoteDetails?.records[0].vrnA__Account__r.name}`,
                            toEmail: {
                                email: viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.email || undefined,
                                name: viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.name || undefined,
                            },
                        }}
                    />
                </Grid>
                {isEdit && (
                    <Grid item xs={1} className={classes.editSection} onClick={editFromView} role='editFromView'>
                        <img src={Edit} className={classes.imageDiv} />
                        <div className={classes.editText}>Edit</div>
                    </Grid>
                )}
                {props.viewQuoteDetails?.records[0]?.referral_Status && (
                    <Grid item xs={1} className={classes.referralStatus}>
                        {props.viewQuoteDetails?.records[0]?.referral_Status}
                    </Grid>
                )}
                {props.viewQuoteDetails?.records[0]?.quote_Status && (
                    <Grid
                        item
                        xs={1}
                        className={`${selectStatusBackgroundColor(
                            props.viewQuoteDetails?.records[0]?.quote_Status &&
                                props.viewQuoteDetails?.records[0]?.quote_Status
                        )}`}
                    >
                        {props.viewQuoteDetails?.records[0]?.quote_Status}
                    </Grid>
                )}
            </Grid>
            <Grid container xs={12} className={classes.mainGrid}></Grid>
            <Grid container xs={12} className={classes.viewContentGrid}>
                <Grid item xs={12} className={classes.formContainer}>
                    <Form onSubmit={handleSubmit}>
                        {isEdit && files.length > 0 && (
                            <CustomizedAccordions title={'Quote Documents'} panel="documents" defaultOpen="documents">
                                <Grid container xs={12} className={classes.documentContainer} spacing={2}>
                                    {files?.map((document: any, index: number) => (
                                        <Grid item xs={5} key={index} className={classes.documentItem}>
                                            <Grid container xs={12}>
                                                <Grid item xs={9} role='files'>
                                                    <a
                                                        href={document.fileLink}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        className={classes.documentLink}
                                                    >
                                                        {document.fileName}
                                                    </a>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {document.createdDate &&
                                                        moment.utc(new Date(document.createdDate)).format('MM/DD/yyyy')}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CustomizedAccordions>
                        )}
                        {isEdit ? (
                            <CustomizedAccordions
                                title={'Insured Details'}
                                panel="insured_details"
                                defaultOpen="insured_details"
                            >
                                {insuredDetails}
                            </CustomizedAccordions>
                        ) : (
                            <Grid container xs={12}>
                                <Grid item xs={12} className={classes.insuredHeading}>
                                    Insured Details
                                </Grid>
                                <Grid item xs={12}>
                                    {insuredDetails}
                                </Grid>
                            </Grid>
                        )}
                        {isEdit ? (
                            <CustomizedAccordions title={"Insured's Business Information"} panel="business_info">
                                {insuredBusinessInformation}
                            </CustomizedAccordions>
                        ) : (
                            <Grid container xs={12}>
                                <Grid item xs={12} className={classes.insuredHeading}>
                                    Insured&apos;s Business Information
                                </Grid>
                                <Grid item xs={12}>
                                    {insuredBusinessInformation}
                                </Grid>
                            </Grid>
                        )}
                        {isEdit ? (
                            <CustomizedAccordions title={'Coverage Details'} panel="coverage_details">
                                {coverageDetails}
                            </CustomizedAccordions>
                        ) : (
                            <Grid container xs={12}>
                                <Grid item xs={12} className={classes.insuredHeading}>
                                    Coverage Details
                                </Grid>
                                <Grid item xs={12}>
                                    {coverageDetails}
                                </Grid>
                            </Grid>
                        )}

                        {isEdit ? (
                            <CustomizedAccordions title={'Security Requirements'} panel="security_req">
                                {securityRequirementsSection}
                            </CustomizedAccordions>
                        ) : (
                            <Grid container xs={12}>
                                <Grid item xs={12} className={classes.securityHeading}>
                                    Security Requirements
                                </Grid>
                                <Grid item xs={12}>
                                    {securityRequirementsSection}
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                </Grid>
            </Grid>
            {!isEdit ? (
                <Grid container xs={12} direction="row" className={classes.submitContainer}>
                    <Grid item xs={10}>
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={backToQuotesClick}
                            role="cancel"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            className={classes.submitButton}
                            onClick={() => {
                                props.handleSubmit();
                            }}
                            type="submit"
                            role="save"
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
            <WarningModal
                title="Your Quote was updated successfully"
                buttonVariant="text"
                onCancel={() => {
                    setSuccessModalStatus(false);
                    setValues(initialValues);
                    resetQuote();
                    backToViewPage({
                        id: updateId,
                        limit_type: props?.viewQuoteDetails?.records[0]?.quote_limit_type || 'EVO_Limit_1__c',
                    });
                    setIsEdit(true);
                }}
                confirmModalStatus={successModalStatus}
                role="confirm-delete-modal"
                cancelButtonTitle="Done"
            />
        </>
    );
};

const ViewEditQuoteForm = withFormik<IViewEditQuoteProps, IViewEditQuoteFormValues>({
    mapPropsToValues: (props) => {
        const floatedValue = props.viewQuoteDetails?.records[0]?.gross_Annual_Revenue__c
            .toString()
            .replace('$', '')
            .replaceAll(',', '');
        const formatedGrossValue = Number(floatedValue);
        const amount = formatedGrossValue && currencyFormat(formatedGrossValue, 2, 2);
        const additionalFields = props.viewQuoteDetails?.records[0]?.additional_fields__r;
        const controlFields = props.viewQuoteDetails?.records[0]?.control_questions__r;
        return {
            insuredName: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.name || '',
            hqCountry: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.billingAddress?.country || '',
            website: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.website || '',
            hqStreet: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.billingAddress?.street || '',
            hqCity: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.billingAddress?.city || '',
            hqState: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.billingAddress?.state || '',
            hqZip: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.billingAddress?.postalCode || '',
            estimatedNumberOfEmployees:
                props.viewQuoteDetails?.records[0]?.vrnA__Total_Number_of_Employees__c?.toString() || '',
            grossRevenue: (amount && amount) || '',
            limitValue: props.viewQuoteDetails?.records[0]?.quote_limit || '',
            effectiveDate:
                (props.viewQuoteDetails?.records[0]?.vrnA__Expected_Close_Date__c &&
                    moment(props.viewQuoteDetails?.records[0]?.vrnA__Expected_Close_Date__c).format('MM/DD/yyyy')) ||
                null,
            endorsements__c:
                (props.viewQuoteDetails?.records[0]?.endorsements__c &&
                    props.viewQuoteDetails?.records[0]?.endorsements__c
                        .split(';')
                        .map((line, index) => `${index + 1}. ${line}`)
                        .join('\n')) ||
                '',
            deductible__c:
                currencyFormat(Number(props.viewQuoteDetails?.records[0]?.option_Deductible) || 0, 2, 2) || '$0',
            premium: currencyFormat(Number(props.viewQuoteDetails?.records[0]?.quote_Discount_Premium) || 0, 2, 2),
            totalFees: currencyFormat(props.viewQuoteDetails?.records[0]?.total_Fees__c || 0, 2, 2) || '$0.00',
            cyberCrimeSublimit:
                currencyFormat(props.viewQuoteDetails?.records[0]?.cyber_Crime_Sublimit__c || 0, 2, 2) || '$0.00',
            reputationalHarmLimit: props.viewQuoteDetails?.records[0]?.reputational_Harm_Sublimit__c || '',
            businessActivities: props.viewQuoteDetails?.records[0]?.business_Activities__c || '',
            assignedUnderwriterName: props.viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.name || '',
            assignedUnderwriterEmail: props.viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.email || '',
            dateQuoted:
                (props.viewQuoteDetails?.records[0]?.date_Quoted__c &&
                    moment(props.viewQuoteDetails?.records[0]?.date_Quoted__c).format('MM/DD/yyyy')) ||
                null,
            brokerCommission:
                (props.viewQuoteDetails?.records[0]?.vrnA__Broker_Commission_Percent__c &&
                    `${props.viewQuoteDetails?.records[0]?.vrnA__Broker_Commission_Percent__c.toFixed(2)}%`) ||
                0,
            Subjectivities: props.viewQuoteDetails?.records[0]?.subjectivities__c || '',
            totalPayable: currencyFormat(Number(props.viewQuoteDetails?.records[0]?.total_Payable) || 0, 2, 2),
            totalSurplus:
                props.viewQuoteDetails?.records[0]?.total_Payable &&
                props.viewQuoteDetails?.records[0]?.total_Payable > 0
                    ? currencyFormat(
                          Number(props.viewQuoteDetails?.records[0]?.total_Payable) -
                              Number(props.viewQuoteDetails?.records[0]?.quote_Discount_Premium) -
                              Number(props.viewQuoteDetails?.records[0]?.total_Fees__c) || 0,
                          2,
                          2
                      )
                    : '$0',
            industryCode: props.viewQuoteDetails?.records[0]?.vrnA__Account__r?.industry__r?.industryName || '',
            mediaLiability:
                (props.viewQuoteDetails?.records[0]?.coverages__c &&
                    props.viewQuoteDetails?.records[0]?.coverages__c.includes('Media Liability') ?
                    'Full Limits' : 'No Coverage') ||
                '',
            brokerName: props.brokerDetails?.records[0]?.account?.name || '',
            MFA_on_Remote_Network_Enabled: controlFields?.mfA_on_Remote_Network_Enabled__c || 'Unknown',
            MFA_on_Email_Enabled: controlFields?.mfA_on_Email_Enabled__c || 'Unknown',
            MFA_for_Privileged_Accounts: controlFields?.mfA_for_Privileged_Accounts__c || 'Unknown',
            Offline_backups: controlFields?.offline_Backups__c || 'Unknown',
            EDR_Fully_Deployed: controlFields?.edR_Fully_Deployed__c || 'Unknown',
            Network_Monitoring: controlFields?.network_Monitoring__c || 'Unknown',
            Annual_Phishing_Training: controlFields?.annual_Phishing_Training__c || 'Unknown',
            Email_Filtering: controlFields?.email_Filtering__c || 'Unknown',
            Update_Critical_Patches: controlFields?.update_Critical_Patches__c || 'Unknown',
            Anti_virus_Software_and_Firewalls: controlFields?.anti_virus_Software_and_Firewalls__c || 'Unknown',
            Daily_Backups_Critical_Data: controlFields?.daily_Backups_Critical_Data__c || 'Unknown',
            Incident_Greater_than_10k: controlFields?.incident_Greater_than_10k__c || 'Unknown',
            Legal_Action: controlFields?.legal_Action__c || 'Unknown',
            Regulatory_Action: controlFields?.regulatory_Action__c || 'Unknown',
            Cannabis_or_Crypto: controlFields?.cannabis_or_Crypto__c || 'Unknown',
            Penetration_Test_Last_24_mo: controlFields?.penetration_Test_Last_24_mo__c || 'Unknown',
            Aerospace_or_Defense_Ind: controlFields?.aerospace_or_Defense_Ind__c || 'Unknown',
            Grantmaking_or_giving_foundation: controlFields?.grantmaking_or_giving_foundation__c || 'Unknown',
            Provide_Technology_Services: controlFields?.provide_Technology_Services__c || 'Unknown',
            Dual_Authorization_Change: controlFields?.dual_Authorization_Change__c || 'Unknown',
            Dual_Authorization_Request: controlFields?.dual_Authorization_Request__c || 'Unknown',
            Estimated_Number_of_PII: additionalFields?.estimated_Number_of_PII__c || 0,
            EOL_or_EOS_Software: controlFields?.eoL_or_EOS_Software__c || 'Unknown',
            emailFilteringSolution: controlFields?.email_Filtering__c || '',
        };
    },
    validate: (values) => {
        const errors: any = {};
        if (values.grossRevenue == '$0.00' || !values.grossRevenue) {
            errors.grossRevenue = errorMessages().grossRevenue;
        }
        return errors;
    },
    validationSchema: Yup.object({
        businessActivities: Yup.string().trim().required(errorMessages().required),
        estimatedNumberOfEmployees: Yup.string()
            .required(errorMessages().required)
            .max(8, errorMessages('8').valueLength)
            .matches(/^[0-9]+$/, errorMessages().wholeNumbersOnly),
        grossRevenue: Yup.string().max(18, errorMessages('18').valueLength).required(errorMessages().required),
        limitValue: Yup.string().trim().required(errorMessages().required),
        effectiveDate: Yup.string().nullable().required(errorMessages().required),
        Estimated_Number_of_PII: Yup.number().integer().typeError(errorMessages().validNumber)
        .max(piiLimit, errorMessages().maxLimit),
    }),
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        const floatedValue = values.grossRevenue.replace('$', '').replaceAll(',', '');
        const formatedGrossValue = Number(floatedValue);
        const data = {
            Mode_of_Submission: 'Broker Portal',
            PolicyNumber: props.isView.id,
            Insured_Id: props.viewQuoteDetails?.records[0]?.vrnA__Account__c || '',
            Business_Activities: values.businessActivities,
            Gross_annual_revenue: formatedGrossValue.toString(),
            Total_no_of_employees: values.estimatedNumberOfEmployees,
            Expected_Close_Date: moment(values.effectiveDate).format('yyyy-MM-DD'),
            Quote_limit_type: props.viewQuoteDetails?.records[0]?.quote_limit_type || '',
            Quote_limit: values.limitValue.toString(),
            InsuredAccount: {
                Primary_Contact: props?.viewQuoteDetails?.records[0]?.vrnA__Account__r.primary_Contact__c || null,
                Retail_Broker: props.brokerDetails?.records[0].accountId || null
            },
            Primary_Contact: props.brokerDetails?.records[0]?.id || null,
            ControlQuestions: {
                mfa_on_remote_network_enabled: values.MFA_on_Remote_Network_Enabled,
                mfa_on_email_enabled: values.MFA_on_Email_Enabled,
                mfa_for_priveleged_accounts: values.MFA_for_Privileged_Accounts,
                offline_backups: values.Offline_backups,
                edr_fully_deployed: values.EDR_Fully_Deployed,
                network_Monitoring: values.Network_Monitoring,
                annual_phising_training: values.Annual_Phishing_Training,
                email_filtering: values.Email_Filtering,
                update_critical_patches: values.Update_Critical_Patches,
                up_to_date_anti_virus_firewall: values.Anti_virus_Software_and_Firewalls,
                daily_critical_data_backup: values.Daily_Backups_Critical_Data,
                incident_Greater_than_10k: values.Incident_Greater_than_10k,
                cyber_event_legal_action: values.Legal_Action,
                cyber_event_regulatory_action: values.Regulatory_Action,
                direct_supply_cannabis_cryptocurrency: values.Cannabis_or_Crypto,
                penetration_Test_Last24M: values.Penetration_Test_Last_24_mo,
                products_for_aerospace_defense: values.Aerospace_or_Defense_Ind,
                grantmaking_giving_foundation: values.Grantmaking_or_giving_foundation,
                technology_services: values.Provide_Technology_Services,
                dual_authorization_change: values.Dual_Authorization_Change,
                dual_authorization_request: values.Dual_Authorization_Request,
                eol_or_eos_software: values.EOL_or_EOS_Software,
            },
            AdditionalFields: {
                estimated_number_of_pii: values.Estimated_Number_of_PII,
            },
        };
        props.updateQuote(data);
    },
})(ViewEditForm);

const mapStateToProps = (state: IReduxState) => ({
    insuredDetails: state.quote.insuredDetails,
    industryActivities: state.quote.industryActivities,
    states: state.quote.states,
    viewQuoteDetails: state.quote.viewQuoteDetails,
    onCreateQuoteSuccess: state.quote.onCreateQuoteSuccess,
    updateId: state.quote.updateId,
    brokerDetails: state.quote.brokerDetails,
    files: state.quote?.files,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    resetQuote: () => dispatch(resetQuoteSuccessAction()),
    getIndustryActivities: () => dispatch(getIndustryActivitiesAction()),
    getStates: () => dispatch(getStatesAction()),
    clearView: () => dispatch(clearViewAction()),
    updateQuote: (data: IUpdateQuoteRequest) => dispatch(updateQuoteAction(data)),
    getFilesSuccess: (data: any) => dispatch(getFilesActionSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEditQuoteForm);
