import React, { Dispatch } from 'react';
import FormComponent from '../../components/form-field/form-field';
import { withFormik, Form, FormikProps } from 'formik';
import { Button, Grid } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { IReduxState } from '../../modules/store';
import { backTo } from '../../assets/icons';
import moment from 'moment';
import { IViewRenewalDetails } from '../../interface/renewals/viewRenewals';
import { IBrokerDetails } from '../../interface/quotes/getBrokerDetails';
import HelpEmail from '../help-email/help-email';
import CustomizedAccordions from '../../components/accordian/accordian';
import { getFilesActionSuccess, sendEmailAction } from '../../modules/quotes';
import { AnyAction } from 'redux';
import { radiovalues, securityRequirements } from '../../constants/securityRequirements';
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ISecurityReq } from '../../interface/common';
import { useMsal } from '@azure/msal-react';
import { lossrunsEmail } from '../../constants/helpEmail';
import { currencyFormat } from '../../utils/currencyFormat';

interface IViewPolicyProps {
    backToRenewalsClick: () => void;
    viewRenewalDetails?: IViewRenewalDetails;
    brokerDetails?: IBrokerDetails;
    documentList?: any;
    getFilesSuccess: (data: any) => void;
    sendEmail: (data: any) => void;
    viewClaim: (id?: string, limit?: string | null, policyNumber?: string) => void;
}
interface IViewPolicyValues {
    insuredName: string;
    website: string;
    hqStreet: string;
    hqCity: string;
    hqState: string;
    hqZip: string;
    grossRevenue: string;
    limits: string[];
    limitValue: string | null;
    effectiveDate: string | null;
    hqCountry: string;
    endorsements__c: string;
    deductible__c: number | string;
    premium: string;
    totalFees: number | string;
    cyberCrimeSublimit: string;
    reputationalHarmLimit: string;
    businessActivities: string;
    assignedUnderwriterName: string;
    assignedUnderwriterEmail: string;
    dateQuoted: string | null;
    estimatedNumberOfEmployees: string;
    brokerCommission: number | string;
    Subjectivities: string;
    totalPayable: number | string;
    totalSurplus: number;
    industryCode: string;
    mediaLiability: string;
    brokerName: string;
    emailFilteringSolution: string;
    MFA_on_Remote_Network_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_on_Email_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_for_Privileged_Accounts: 'Yes' | 'No' | 'Unknown' | null;
    Offline_backups: 'Yes' | 'No' | 'Unknown' | null;
    EDR_Fully_Deployed: 'Yes' | 'No' | 'Unknown' | null;
    Network_Monitoring: 'Yes' | 'No' | 'Unknown' | null;
    Annual_Phishing_Training: 'Yes' | 'No' | 'Unknown' | null;
    Email_Filtering: 'Yes' | 'No' | 'Unknown' | null;
    Update_Critical_Patches: 'Yes' | 'No' | 'Unknown' | null;
    Anti_virus_Software_and_Firewalls: 'Yes' | 'No' | 'Unknown' | null;
    Daily_Backups_Critical_Data: 'Yes' | 'No' | 'Unknown' | null;
    Incident_Greater_than_10k: 'Yes' | 'No' | 'Unknown' | null;
    Legal_Action: 'Yes' | 'No' | 'Unknown' | null;
    Regulatory_Action: 'Yes' | 'No' | 'Unknown' | null;
    Cannabis_or_Crypto: 'Yes' | 'No' | 'Unknown' | null;
    Penetration_Test_Last_24_mo: 'Yes' | 'No' | 'Unknown' | null;
    Aerospace_or_Defense_Ind: 'Yes' | 'No' | 'Unknown' | null;
    Grantmaking_or_giving_foundation: 'Yes' | 'No' | 'Unknown' | null;
    Provide_Technology_Services: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Change: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Request: 'Yes' | 'No' | 'Unknown' | null;
    Estimated_Number_of_PII: number | null;
    EOL_or_EOS_Software: 'Yes' | 'No' | 'Unknown' | null;
}
const ViewRenewals = (props: IViewPolicyProps & FormikProps<IViewPolicyValues>) => {
    const {
        handleChange,
        values,
        backToRenewalsClick,
        handleSubmit,
        viewRenewalDetails,
        documentList,
        getFilesSuccess,
        setFieldValue,
        sendEmail,
        viewClaim
    } = props;
    const { classes } = useStyles();
    const [options, setOptions] = React.useState<any[]>([]);
    const { accounts } = useMsal();
    const email = accounts[0]?.username;
    React.useEffect(() => {
        return () => {
            getFilesSuccess([]);
        };
    }, []);
    React.useEffect(() => {
        const checkedValues = [];
        checkedValues.push(
            viewRenewalDetails?.evO_Limit_1__c?.toString(),
            viewRenewalDetails?.evO_Limit_2__c?.toString(),
            viewRenewalDetails?.evO_Limit_3__c?.toString(),
            viewRenewalDetails?.evO_Limit_4__c?.toString()
        );
        const filteredCheckedValues = checkedValues.filter((item) => {
            return item != null;
        });
        setOptions(filteredCheckedValues);
    }, [viewRenewalDetails]);
    React.useEffect(() => {
        setFieldValue('limits', options);
    }, [options]);
    const selectStatusBackgroundColor = (status?: string) => {
        switch (status) {
            case 'UPCOMING RENEWAL':
                return classes.upcomingDiv;
            case 'EXPIRING RENEWAL':
                return classes.expiringSoonDiv;
            case 'POLICY EXPIRED':
                return classes.policyExpiredDiv;
        }
    };
    const sendLossRunsEmail = () => {
        const data = {
            FromEmail: 'noreply@evolvemga.com',
            ToEmail: lossrunsEmail,
            ccEmail: email,
            Subject: `Loss Run Request - ${viewRenewalDetails?.vrnA__Account__r?.name}`,
            // eslint-disable-next-line max-len
            Body: `<html><head/><body><div>Hello,<br/><br/>Thank you for requesting loss runs! We will process your request and have them over shortly. <br/><p>Policy Number - ${viewRenewalDetails?.vrnA__Policy_Number__c}</p><br/><br/>Thank you,<br/>Evolve MGA</div></body></html>`,
            Message: '',
        };
        sendEmail(data);
    };
    return (
        <Grid container className={classes.quotesContainer}>
            <Grid container className={classes.container}>
                <Grid item xs={4} className={classes.backToQuotesDiv}>
                    <img src={backTo} />
                    <Button className={classes.backToQuotesButton} onClick={backToRenewalsClick} role="backToRenewal">
                        Back to renewals
                    </Button>
                </Grid>
                <Grid xs={2} className={classes.claimsHistory} onClick={sendLossRunsEmail} role='requestLossRun'>
                    Request Loss Runs
                </Grid>
                <Grid
                    item
                    xs={2}
                    className={classes.claimsHistory}
                    role='claimsHistoryInRenewalView'
                    onClick={() => viewClaim(viewRenewalDetails?.id || '',
                        viewRenewalDetails?.quote_limit_type, viewRenewalDetails?.vrnA__Policy_Number__c)}
                >
                    Claims History
                </Grid>
                <Grid xs={2}>
                    <HelpEmail
                        emailDetails={{
                            brokerName:
                                (viewRenewalDetails?.vrnA__Broker__r?.broker_Code__c ||
                                    viewRenewalDetails?.vrnA__Broker__c ||
                                    '') +
                                    ': ' +
                                    viewRenewalDetails?.vrnA__Broker__r?.name || '',
                            subject: `Renewal: ${viewRenewalDetails?.vrnA__Account__r?.name}`,
                            toEmail: {
                                email: viewRenewalDetails?.vrnA__Account_Manager__r?.email || undefined,
                                name: viewRenewalDetails?.vrnA__Account_Manager__r?.name || undefined,
                            },
                        }}
                    />
                </Grid>
                {viewRenewalDetails?.renewal_Status && (
                    <Grid
                        item
                        xs={2}
                        className={`${classes.statusDiv} ${selectStatusBackgroundColor(
                            viewRenewalDetails?.renewal_Status?.toUpperCase()
                        )}`}
                        style={{ height: '27px' }}
                        role="renewalStatus"
                    >
                        {viewRenewalDetails?.renewal_Status}
                    </Grid>
                )}
            </Grid>
            <Grid container xs={12} className={classes.mainGrid}></Grid>
            <Grid container xs={12} className={classes.viewContentGrid}>
                <Grid item xs={12} className={classes.formContainer}>
                    <Form onSubmit={handleSubmit}>
                        {documentList.length > 0 && (
                            <CustomizedAccordions
                                title={'Quote Documents'}
                                panel="documents"
                                defaultOpen="documents"
                                role="documents"
                            >
                                <Grid container xs={12} className={classes.documentContainer} spacing={2}>
                                    {documentList.map((document: any, index: any) => (
                                        <Grid item xs={5} key={index} className={classes.documentItem}>
                                            <Grid container xs={12}>
                                                <Grid item xs={9}>
                                                    <a
                                                        href={document.fileLink}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        className={classes.documentLink}
                                                    >
                                                        {document.fileName}
                                                    </a>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {document.createdDate &&
                                                        moment.utc(new Date(document.createdDate)).format('MM/DD/yyyy')}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CustomizedAccordions>
                        )}
                        <CustomizedAccordions
                            title={'Insured Details'}
                            panel="insured_details"
                            defaultOpen="insured_details"
                            role="insuredDetails"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Business Name"
                                        id="insuredName"
                                        placeholder="Insured's Name"
                                        className={classes.inputField1}
                                        value={values.insuredName}
                                        name="insuredName"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Country"
                                        id="hqCountry"
                                        placeholder="Insured Country"
                                        className={classes.inputField}
                                        value={values.hqCountry}
                                        name="hqCountry"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured State"
                                        id="physicalState"
                                        placeholder="Insured State"
                                        className={classes.inputField}
                                        value={values.hqState}
                                        name="physicalState"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured City"
                                        id="hqCity"
                                        placeholder="Insured City"
                                        className={classes.inputField}
                                        value={values.hqCity}
                                        name="hqCity"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Street"
                                        id="hqStreet"
                                        placeholder="Insured Street"
                                        className={classes.inputField}
                                        value={values.hqStreet}
                                        name="hqStreet"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Zip/Postal Code"
                                        id="hqZip"
                                        placeholder="Insured Zip/Postal Code"
                                        className={classes.inputField}
                                        value={values.hqZip}
                                        name="hqZip"
                                        isReadOnly
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={"Insured's Business Information"}
                            panel="business_info"
                            role="businessInfo"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Website"
                                        id="website"
                                        placeholder="Website"
                                        className={classes.inputField}
                                        value={values.website}
                                        name="website"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Business Activities"
                                        id="businessActivities"
                                        placeholder="Business Activities"
                                        className={classes.inputField}
                                        value={values.businessActivities}
                                        name="businessActivities"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Gross Annual Revenue"
                                        id="grossRevenue"
                                        placeholder="Gross Annual Revenue"
                                        className={classes.inputField}
                                        value={values.grossRevenue}
                                        name="grossRevenue"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Industry Code"
                                        id="Industry__c"
                                        placeholder="Industry Code"
                                        className={classes.inputField}
                                        value={values.industryCode}
                                        name="Industry__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Assigned Underwriter Name"
                                        id="assignedUnderwriterName"
                                        placeholder="Assigned Underwriter Name"
                                        className={classes.inputField}
                                        value={values.assignedUnderwriterName}
                                        name="assignedUnderwriterName"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Assigned Underwriter Email"
                                        id="assignedUnderwriterEmail"
                                        placeholder="Assigned Underwriter Email"
                                        className={classes.inputField}
                                        value={values.assignedUnderwriterEmail}
                                        name="assignedUnderwriterEmail"
                                        isReadOnly
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={'Coverage Details'}
                            panel="coverage_details"
                            role="coverageDetails"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="textarea"
                                        handleChange={handleChange}
                                        label="Endorsements"
                                        id="endorsements__c"
                                        placeholder="Endorsements"
                                        className={classes.textAreaField}
                                        value={values.endorsements__c}
                                        name="endorsements__c"
                                        isReadOnly
                                        rows={3}
                                        rowsMax={3}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Limit"
                                        id="limit__c"
                                        placeholder="Limit Bound"
                                        className={classes.inputField}
                                        value={values.limitValue}
                                        name="limit__c"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Deductible"
                                        id="deductible__c"
                                        placeholder="Deductible Bound"
                                        className={classes.inputField}
                                        value={values.deductible__c}
                                        name="deductible__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Premium"
                                        id="premium"
                                        placeholder="Premium"
                                        className={classes.inputField}
                                        value={values.premium}
                                        name="premium"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Total Fees"
                                        id="totalFees"
                                        placeholder="Total Fees"
                                        className={classes.inputField}
                                        value={values.totalFees}
                                        name="totalFees"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Total Surplus Lines, Taxes & Fees"
                                        id="totalSurplus"
                                        className={classes.inputField}
                                        value={values.totalSurplus}
                                        name="totalSurplus"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Cyber Crime Limit"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="cyberCrimeSublimit"
                                        placeholder="Cyber Crime Limit"
                                        className={classes.inputField}
                                        value={values.cyberCrimeSublimit}
                                        name="cyberCrimeSublimit"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Reputational Harm Limit"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="reputationalHarmLimit"
                                        placeholder="Reputational Harm Limit"
                                        className={classes.inputField}
                                        value={values.reputationalHarmLimit}
                                        name="reputationalHarmLimit"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Media Liability"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="mediaLiability"
                                        placeholder="Media Liability"
                                        className={classes.inputField}
                                        value={values.mediaLiability}
                                        name="mediaLiability"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Effective Date"
                                        id="effectiveDate"
                                        placeholder="Effective Date"
                                        className={classes.inputField}
                                        value={values.effectiveDate}
                                        name="effectiveDate"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Date Quoted"
                                        id="dateQuoted"
                                        placeholder="Date Quoted"
                                        className={classes.inputField}
                                        value={values.dateQuoted}
                                        name="dateQuoted"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Estimated Number of Employees"
                                        id="estimatedNumberOfEmployees"
                                        placeholder="Estimated Number of Employees"
                                        className={classes.inputField}
                                        value={values.estimatedNumberOfEmployees}
                                        name="estimatedNumberOfEmployees"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Broker Commission"
                                        id="brokerCommission"
                                        placeholder="Broker Commission"
                                        className={classes.inputField}
                                        value={values.brokerCommission}
                                        name="brokerCommission"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Total Payable"
                                        id="totalPayable"
                                        placeholder="Total Payable"
                                        className={classes.inputField}
                                        value={values.totalPayable}
                                        name="totalPayable"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Subjectivities"
                                        id="subjectivities"
                                        placeholder="Subjectivities"
                                        className={classes.inputField}
                                        value={values.Subjectivities}
                                        name="Subjectivities"
                                        isReadOnly
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={'Security Requirements'}
                            panel="security_req"
                            role="securityRequirements"
                        >
                            <Grid container spacing={2} className={classes.securityMainContainer}>
                                <Grid item>
                                    {securityRequirements.map((req: ISecurityReq, index) => (
                                        <Grid
                                            container
                                            xs={11}
                                            spacing={2}
                                            key={index}
                                            className={classes.securityContainerView}
                                        >
                                            <Grid item xs={8}>
                                                {req.label}
                                                {req?.tooltipContent && (
                                                    <Tooltip title={req.tooltipContent} placement="top-start">
                                                        <IconButton className={classes.tooltip}>
                                                            <InfoOutlinedIcon className={classes.tooltipIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {req.valueLabel === 'Estimated_Number_of_PII' ? (
                                                    <FormComponent
                                                        component="input"
                                                        handleChange={handleChange}
                                                        id="Estimated_Number_of_PII"
                                                        placeholder="Estimated Number of PII"
                                                        className={classes.inputField}
                                                        value={values.Estimated_Number_of_PII}
                                                        name="Estimated_Number_of_PII"
                                                        isReadOnly
                                                    />
                                                ) : (
                                                    <FormComponent
                                                        required
                                                        value={(values as any)[req.valueLabel]}
                                                        name={req.valueLabel}
                                                        component="radio"
                                                        id={req.valueLabel}
                                                        radioOptions={radiovalues}
                                                        disabled
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                    </Form>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ViewRenewalsForm = withFormik<IViewPolicyProps, IViewPolicyValues>({
    mapPropsToValues: (props) => {
        const floatedValue =
            props.viewRenewalDetails?.gross_Annual_Revenue__c &&
            props.viewRenewalDetails?.gross_Annual_Revenue__c.toString().replace('$', '').replaceAll(',', '');
        const formatedGrossValue = Number(floatedValue);
        const amount = formatedGrossValue && currencyFormat(formatedGrossValue);
        const additionalFields = props.viewRenewalDetails?.additional_fields__r;
        const controlFields = props.viewRenewalDetails?.control_questions__r;
        return {
            insuredName: props.viewRenewalDetails?.vrnA__Account__r?.name || '',
            hqCountry: props.viewRenewalDetails?.vrnA__Account__r?.billingAddress?.country || '',
            website: props.viewRenewalDetails?.vrnA__Account__r?.website || '',
            hqStreet: props.viewRenewalDetails?.vrnA__Account__r?.billingAddress?.street || '',
            hqCity: props.viewRenewalDetails?.vrnA__Account__r?.billingAddress?.city || '',
            hqState: props.viewRenewalDetails?.vrnA__Account__r?.billingAddress?.state || '',
            hqZip: props.viewRenewalDetails?.vrnA__Account__r?.billingAddress?.postalCode || '',
            estimatedNumberOfEmployees:
                (props.viewRenewalDetails?.vrnA__Total_Number_of_Employees__c &&
                    props.viewRenewalDetails?.vrnA__Total_Number_of_Employees__c?.toString()) ||
                '',
            grossRevenue: (amount && amount) || '',
            limitValue: currencyFormat(Number(props.viewRenewalDetails?.quote_limit),2,2) || '',
            limits: [],
            effectiveDate:
                (props.viewRenewalDetails?.vrnA__Expected_Close_Date__c &&
                    moment(props.viewRenewalDetails?.vrnA__Expected_Close_Date__c).format('MM/DD/yyyy')) ||
                null,
            endorsements__c:
                (props.viewRenewalDetails?.endorsements__c &&
                    props.viewRenewalDetails?.endorsements__c
                        .split(';')
                        .map((line, index) => `${index + 1}. ${line}`)
                        .join('\n')) ||
                '',
            deductible__c:
                ((props.viewRenewalDetails?.option_Deductible ||
                    props.viewRenewalDetails?.option_Deductible == 0) &&
                   currencyFormat(props.viewRenewalDetails?.option_Deductible,0))
                   ||
                   '',
            premium:
                currencyFormat(Number(props.viewRenewalDetails?.quote_Discount_Premium) || 0)
                ||
                currencyFormat(Number(props.viewRenewalDetails?.quoted_Base_1__c) || 0),
            totalFees:
                ((props.viewRenewalDetails?.total_Fees__c || props.viewRenewalDetails?.total_Fees__c == 0) &&
                currencyFormat(props.viewRenewalDetails?.total_Fees__c,0)
                )
                ||
            '',
            cyberCrimeSublimit:
            ((props.viewRenewalDetails?.cyber_Crime_Sublimit__c ||
                props.viewRenewalDetails?.cyber_Crime_Sublimit__c == 0) &&
                currencyFormat(props.viewRenewalDetails?.cyber_Crime_Sublimit__c,0)
                ) ||
            '',
            reputationalHarmLimit: props.viewRenewalDetails?.reputational_Harm_Sublimit__c || '',
            businessActivities: props.viewRenewalDetails?.business_Activities__c || '',
            assignedUnderwriterName: props.viewRenewalDetails?.vrnA__Account_Manager__r?.name || '',
            assignedUnderwriterEmail: props.viewRenewalDetails?.vrnA__Account_Manager__r?.email || '',
            dateQuoted:
                (props.viewRenewalDetails?.date_Quoted__c &&
                    moment(props.viewRenewalDetails?.date_Quoted__c).format('MM/DD/yyyy')) ||
                null,
            brokerCommission:
                (props.viewRenewalDetails?.vrnA__Broker_Commission_Percent__c &&
                    `${props.viewRenewalDetails?.vrnA__Broker_Commission_Percent__c.toFixed(2)}%`) ||
                0,
            Subjectivities: props.viewRenewalDetails?.subjectivities__c || '',
            totalPayable:
                currencyFormat(Number(props.viewRenewalDetails?.total_Payable) || 0)
                ||
                currencyFormat(Number(props.viewRenewalDetails?.option_1_Total_Payable__c) || 0),
            totalSurplus:
                Number(props.viewRenewalDetails?.vrnA__State_Municipality_Tax__c) +
                    Number(props.viewRenewalDetails?.stamping_Fee_Amount__c) +
                    Number(props.viewRenewalDetails?.surcharge_Amount__c) +
                    Number(props.viewRenewalDetails?.surplus_Lines_Tax_Amount__c) || 0,
            industryCode: props.viewRenewalDetails?.vrnA__Account__r?.industry__r?.industryName || '',
            mediaLiability:
                (props.viewRenewalDetails?.coverages__c &&
                    props.viewRenewalDetails?.coverages__c.includes('Media Liability') ?
                    'Full Limits' : 'No Coverage') ||
                '',
            brokerName: props.brokerDetails?.records[0]?.account?.name || '',
            MFA_on_Remote_Network_Enabled: controlFields?.mfA_on_Remote_Network_Enabled__c || 'Unknown',
            MFA_on_Email_Enabled: controlFields?.mfA_on_Email_Enabled__c || 'Unknown',
            MFA_for_Privileged_Accounts: controlFields?.mfA_for_Privileged_Accounts__c || 'Unknown',
            Offline_backups: controlFields?.offline_Backups__c || 'Unknown',
            EDR_Fully_Deployed: controlFields?.edR_Fully_Deployed__c || 'Unknown',
            Network_Monitoring: controlFields?.network_Monitoring__c || 'Unknown',
            Annual_Phishing_Training: controlFields?.annual_Phishing_Training__c || 'Unknown',
            Email_Filtering: controlFields?.email_Filtering__c || 'Unknown',
            Update_Critical_Patches: controlFields?.update_Critical_Patches__c || 'Unknown',
            Anti_virus_Software_and_Firewalls: controlFields?.anti_virus_Software_and_Firewalls__c || 'Unknown',
            Daily_Backups_Critical_Data: controlFields?.daily_Backups_Critical_Data__c || 'Unknown',
            Incident_Greater_than_10k: controlFields?.incident_Greater_than_10k__c || 'Unknown',
            Legal_Action: controlFields?.legal_Action__c || 'Unknown',
            Regulatory_Action: controlFields?.regulatory_Action__c || 'Unknown',
            Cannabis_or_Crypto: controlFields?.cannabis_or_Crypto__c || 'Unknown',
            Penetration_Test_Last_24_mo: controlFields?.penetration_Test_Last_24_mo__c || 'Unknown',
            Aerospace_or_Defense_Ind: controlFields?.aerospace_or_Defense_Ind__c || 'Unknown',
            Grantmaking_or_giving_foundation: controlFields?.grantmaking_or_giving_foundation__c || 'Unknown',
            Provide_Technology_Services: controlFields?.provide_Technology_Services__c || 'Unknown',
            Dual_Authorization_Change: controlFields?.dual_Authorization_Change__c || 'Unknown',
            Dual_Authorization_Request: controlFields?.dual_Authorization_Request__c || 'Unknown',
            Estimated_Number_of_PII: additionalFields?.estimated_Number_of_PII__c || 0,
            EOL_or_EOS_Software: controlFields?.eoL_or_EOS_Software__c || 'Unknown',
            emailFilteringSolution: controlFields?.email_Filtering__c || '',
        };
    },
    enableReinitialize: true,
    handleSubmit: () => {
        //fomik handlesubmit
    },
})(ViewRenewals);

const mapStateToProps = (state: IReduxState) => ({
    viewRenewalDetails: state.renewals.viewRenewalDetails,
    brokerDetails: state.quote.brokerDetails,
    documentList: state.quote.files,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getFilesSuccess: (data: any) => dispatch(getFilesActionSuccess(data)),
    sendEmail: (data: any) => dispatch(sendEmailAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRenewalsForm);
