import { IRenewals } from '../interface/renewals/renewalsTable';

export const renewalsResponse: IRenewals[] = [
    {
        id: '12345',
        vrnA__Status__c: null,
        endorsements__c: null,
        coverages__c: null,
        evO_Limit_1__c: 1,
        evO_Limit_2__c: null,
        evO_Limit_3__c: null,
        evO_Limit_4__c: null,
        option_1_Deductible__c: 1,
        option_2_Deductible__c: null,
        option_3_Deductible__c: null,
        option_4_Deductible__c: null,
        discount_Total_Payable_1__c: 0.0,
        discount_Total_Payable_2__c: 0.0,
        discount_Total_Payable_3__c: 0.0,
        discount_Total_Payable_4__c: 0.0,
        option_1_Total_Payable__c: null,
        option_2_Total_Payable__c: null,
        option_3_Total_Payable__c: null,
        option_4_Total_Payable__c: null,
        renewal_Status__c: 'Send Renewal App',
        renewal_Status: 'policy expired',
        quoted_Base_1__c: 0.0,
        discount_Quoted_Premium_1__c: 0.0,
        total_Fees__c: 300.0,
        vrnA__State_Municipality_Tax__c: null,
        stamping_Fee_Amount__c: 0.0,
        surcharge_Amount__c: 0.0,
        surplus_Lines_Tax_Amount__c: 0.0,
        cyber_Crime_Sublimit__c: null,
        reputational_Harm_Sublimit__c: null,
        business_Activities__c: null,
        date_Quoted__c: '2023-01-20',
        vrnA__Account_Manager__c: '0056g000004GtwjAAC',
        gross_Annual_Revenue__c: null,
        vrnA__Total_Number_of_Employees__c: null,
        vrnA__Broker_Commission_Percent__c: 15.0,
        vrnA__Expected_Close_Date__c: null,
        vrnA__Account__c: '0010R00001NDuKgQAL',
        vrnA__Account__r: {
            name: 'user 1',
            billingAddress: {
                city: ' Reno',
                country: 'United States',
                countryCode: 'US',
                postalCode: 910923,
                state: 'Nevada',
                stateCode: 'NV',
                street: '123 Mian St',
            },
            recordTypeId: null,
            website: 'www.cvs.com',
            broker_Commission__c: 0.0,
            industry__c: 'a3Y6g000002Blk1EAC',
            industry__r: {
                name: 'RL01',
                industryName: null,
                combo__c: null,
            },
            vrnA__MGA_Broker_Checkbox__c: false,
            retail_Broker__c: null,
            retail_Broker__r: null,
        },
        subjectivities__c: null,
        prior_Policy_Expiration_Date__c: '2022-03-22',
        vrnA__Expiration_Date__c: '2022-03-22',
        vrnA__Effective_Date__c: '2022-03-22',
        evolve_Prior_Policy_Expiration_Date__c: 'string',
        vrnA__Broker__r: 'string',
        quote_Status: 'string',
        quote_limit: 123,
        quote_limit_type: 'string',
        quote_Deductible: 123,
        quote_Discount_Premium: 123,
        total_Payable: 'string',
        option_Deductible: 'string',
        quote_Duplicate: 'string',
        referral_Status__c: 'string',
        referral_Status: 'string',
        vrnA__Policy_Number__c: '1234',
    },
    {
        id: 'a0W0R000005aAchUAE',
        vrnA__Status__c: null,
        endorsements__c: null,
        coverages__c: null,
        evO_Limit_1__c: 5,
        evO_Limit_2__c: null,
        evO_Limit_3__c: null,
        evO_Limit_4__c: null,
        option_1_Deductible__c: 2,
        option_2_Deductible__c: null,
        option_3_Deductible__c: null,
        option_4_Deductible__c: null,
        discount_Total_Payable_1__c: 0.0,
        discount_Total_Payable_2__c: 0.0,
        discount_Total_Payable_3__c: 0.0,
        discount_Total_Payable_4__c: 0.0,
        option_1_Total_Payable__c: null,
        option_2_Total_Payable__c: null,
        option_3_Total_Payable__c: null,
        option_4_Total_Payable__c: null,
        renewal_Status__c: 'Send Renewal App',
        renewal_Status: 'upcoming renewal',
        quoted_Base_1__c: 0.0,
        discount_Quoted_Premium_1__c: 0.0,
        total_Fees__c: 300.0,
        vrnA__State_Municipality_Tax__c: 0.0,
        stamping_Fee_Amount__c: 0.0,
        surcharge_Amount__c: 0.0,
        surplus_Lines_Tax_Amount__c: 0.0,
        cyber_Crime_Sublimit__c: null,
        reputational_Harm_Sublimit__c: null,
        business_Activities__c: null,
        date_Quoted__c: '2023-01-26',
        vrnA__Account_Manager__c: '0056g000004GtwjAAC',
        gross_Annual_Revenue__c: null,
        vrnA__Total_Number_of_Employees__c: 1.0,
        vrnA__Broker_Commission_Percent__c: 0.0,
        vrnA__Expected_Close_Date__c: '2023-01-20',
        vrnA__Account__c: '0010R00001O6IqhQAF',
        vrnA__Account__r: {
            name: 'user 2',
            billingAddress: {
                city: 'albany',
                country: 'United States',
                countryCode: 'US',
                postalCode: 94706,
                state: 'California',
                stateCode: 'CA',
                street: '1104 santa fe ave',
            },
            recordTypeId: null,
            website: 'www.evo.com',
            broker_Commission__c: 0.0,
            industry__c: 'a3Y6g000001opL7EAI',
            industry__r: {
                name: 'RL0114',
                industryName: null,
                combo__c: null,
            },
            vrnA__MGA_Broker_Checkbox__c: false,
            retail_Broker__c: null,
            retail_Broker__r: null,
        },
        subjectivities__c: null,
        prior_Policy_Expiration_Date__c: '2023-01-20',
        vrnA__Expiration_Date__c: '2022-03-22',
        vrnA__Effective_Date__c: '2022-03-22',
        evolve_Prior_Policy_Expiration_Date__c: 'string',
        vrnA__Broker__r: 'string',
        quote_Status: 'string',
        quote_limit: 123,
        quote_limit_type: 'string',
        quote_Deductible: 123,
        quote_Discount_Premium: 123,
        total_Payable: 'string',
        option_Deductible: 'string',
        quote_Duplicate: 'string',
        referral_Status__c: 'string',
        referral_Status: 'string',
        vrnA__Policy_Number__c: '12',
    },
    {
        id: 'a0W0R000005YOe7UAG',
        vrnA__Status__c: null,
        endorsements__c: null,
        coverages__c: null,
        evO_Limit_1__c: 1,
        evO_Limit_2__c: null,
        evO_Limit_3__c: null,
        evO_Limit_4__c: null,
        option_1_Deductible__c: 9,
        option_2_Deductible__c: null,
        option_3_Deductible__c: null,
        option_4_Deductible__c: null,
        discount_Total_Payable_1__c: 0.0,
        discount_Total_Payable_2__c: 0.0,
        discount_Total_Payable_3__c: 0.0,
        discount_Total_Payable_4__c: 0.0,
        option_1_Total_Payable__c: null,
        option_2_Total_Payable__c: null,
        option_3_Total_Payable__c: null,
        option_4_Total_Payable__c: null,
        renewal_Status__c: 'Send Renewal App',
        renewal_Status: 'expiring Renewal',
        quoted_Base_1__c: 0.0,
        discount_Quoted_Premium_1__c: 0.0,
        total_Fees__c: 300.0,
        vrnA__State_Municipality_Tax__c: null,
        stamping_Fee_Amount__c: 0.0,
        surcharge_Amount__c: 0.0,
        surplus_Lines_Tax_Amount__c: 0.0,
        cyber_Crime_Sublimit__c: null,
        reputational_Harm_Sublimit__c: null,
        business_Activities__c: null,
        date_Quoted__c: '2022-12-20',
        vrnA__Account_Manager__c: '0056g000004GtwjAAC',
        gross_Annual_Revenue__c: null,
        vrnA__Total_Number_of_Employees__c: null,
        vrnA__Broker_Commission_Percent__c: 15.0,
        vrnA__Expected_Close_Date__c: null,
        vrnA__Account__c: '0010R00001NDuKgQAL',
        vrnA__Account__r: {
            name: 'user 3',
            billingAddress: {
                city: ' Reno',
                country: 'United States',
                countryCode: 'US',
                postalCode: 910923,
                state: 'Nevada',
                stateCode: 'NV',
                street: '123 Mian St',
            },
            recordTypeId: null,
            website: 'www.cvs.com',
            broker_Commission__c: 0.0,
            industry__c: 'a3Y6g000002Blk1EAC',
            industry__r: {
                name: 'RL01',
                industryName: null,
                combo__c: null,
            },
            vrnA__MGA_Broker_Checkbox__c: false,
            retail_Broker__c: null,
            retail_Broker__r: null,
        },
        subjectivities__c: null,
        prior_Policy_Expiration_Date__c: '2022-03-22',
        vrnA__Expiration_Date__c: '2022-03-22',
        vrnA__Effective_Date__c: '2022-03-22',
        evolve_Prior_Policy_Expiration_Date__c: 'string',
        vrnA__Broker__r: 'string',
        quote_Status: 'string',
        quote_limit: 123,
        quote_limit_type: 'string',
        quote_Deductible: 123,
        quote_Discount_Premium: 123,
        total_Payable: 'string',
        option_Deductible: 'string',
        quote_Duplicate: 'string',
        referral_Status__c: 'string',
        referral_Status: 'string',
        vrnA__Policy_Number__c: '5555',
    },
];

export const renewalsResponseViewCheck: IRenewals[] = [
    {
        id: '12345',
        vrnA__Status__c: null,
        endorsements__c: null,
        coverages__c: null,
        evO_Limit_1__c: 1,
        evO_Limit_2__c: null,
        evO_Limit_3__c: null,
        evO_Limit_4__c: null,
        option_1_Deductible__c: 1,
        option_2_Deductible__c: null,
        option_3_Deductible__c: null,
        option_4_Deductible__c: null,
        discount_Total_Payable_1__c: 0.0,
        discount_Total_Payable_2__c: 0.0,
        discount_Total_Payable_3__c: 0.0,
        discount_Total_Payable_4__c: 0.0,
        option_1_Total_Payable__c: null,
        option_2_Total_Payable__c: null,
        option_3_Total_Payable__c: null,
        option_4_Total_Payable__c: null,
        renewal_Status__c: 'Send Renewal App',
        renewal_Status: 'policy expired',
        quoted_Base_1__c: 0.0,
        discount_Quoted_Premium_1__c: 0.0,
        total_Fees__c: 300.0,
        vrnA__State_Municipality_Tax__c: null,
        stamping_Fee_Amount__c: 0.0,
        surcharge_Amount__c: 0.0,
        surplus_Lines_Tax_Amount__c: 0.0,
        cyber_Crime_Sublimit__c: null,
        reputational_Harm_Sublimit__c: null,
        business_Activities__c: null,
        date_Quoted__c: '2023-01-20',
        vrnA__Account_Manager__c: '0056g000004GtwjAAC',
        gross_Annual_Revenue__c: null,
        vrnA__Total_Number_of_Employees__c: null,
        vrnA__Broker_Commission_Percent__c: 15.0,
        vrnA__Expected_Close_Date__c: null,
        vrnA__Account__c: '0010R00001NDuKgQAL',
        vrnA__Account__r: {
            name: 'user 1',
            billingAddress: {
                city: ' Reno',
                country: 'United States',
                countryCode: 'US',
                postalCode: 910923,
                state: 'Nevada',
                stateCode: 'NV',
                street: '123 Mian St',
            },
            recordTypeId: null,
            website: 'www.cvs.com',
            broker_Commission__c: 0.0,
            industry__c: 'a3Y6g000002Blk1EAC',
            industry__r: {
                name: 'RL01',
                industryName: null,
                combo__c: null,
            },
            vrnA__MGA_Broker_Checkbox__c: false,
            retail_Broker__c: null,
            retail_Broker__r: null,
        },
        subjectivities__c: null,
        prior_Policy_Expiration_Date__c: '2022-03-22',
        vrnA__Expiration_Date__c: '2022-03-22',
        vrnA__Effective_Date__c: '2022-03-22',
        evolve_Prior_Policy_Expiration_Date__c: 'string',
        vrnA__Broker__r: 'string',
        quote_Status: 'string',
        quote_limit: 123,
        quote_limit_type: 'string',
        quote_Deductible: 123,
        quote_Discount_Premium: 123,
        total_Payable: 'string',
        option_Deductible: 'string',
        quote_Duplicate: 'string',
        referral_Status__c: 'string',
        referral_Status: 'string'
    }
];

export const renewalMock: IRenewals = {
    id: '12345',
    vrnA__Status__c: null,
    endorsements__c: null,
    coverages__c: null,
    evO_Limit_1__c: 1,
    evO_Limit_2__c: null,
    evO_Limit_3__c: null,
    evO_Limit_4__c: null,
    option_1_Deductible__c: 1,
    option_2_Deductible__c: null,
    option_3_Deductible__c: null,
    option_4_Deductible__c: null,
    discount_Total_Payable_1__c: 0.0,
    discount_Total_Payable_2__c: 0.0,
    discount_Total_Payable_3__c: 0.0,
    discount_Total_Payable_4__c: 0.0,
    option_1_Total_Payable__c: null,
    option_2_Total_Payable__c: null,
    option_3_Total_Payable__c: null,
    option_4_Total_Payable__c: null,
    renewal_Status__c: 'Send Renewal App',
    renewal_Status: 'policy expired',
    quoted_Base_1__c: 0.0,
    discount_Quoted_Premium_1__c: 0.0,
    total_Fees__c: 300.0,
    vrnA__State_Municipality_Tax__c: null,
    stamping_Fee_Amount__c: 0.0,
    surcharge_Amount__c: 0.0,
    surplus_Lines_Tax_Amount__c: 0.0,
    cyber_Crime_Sublimit__c: null,
    reputational_Harm_Sublimit__c: null,
    business_Activities__c: null,
    date_Quoted__c: '2023-01-20',
    vrnA__Account_Manager__c: '0056g000004GtwjAAC',
    gross_Annual_Revenue__c: null,
    vrnA__Total_Number_of_Employees__c: null,
    vrnA__Broker_Commission_Percent__c: 15.0,
    vrnA__Expected_Close_Date__c: null,
    vrnA__Account__c: '0010R00001NDuKgQAL',
    vrnA__Account__r: {
        name: 'user 1',
        billingAddress: {
            city: ' Reno',
            country: 'United States',
            countryCode: 'US',
            postalCode: 910923,
            state: 'Nevada',
            stateCode: 'NV',
            street: '123 Mian St',
        },
        recordTypeId: null,
        website: 'www.cvs.com',
        broker_Commission__c: 0.0,
        industry__c: 'a3Y6g000002Blk1EAC',
        industry__r: {
            name: 'RL01',
            industryName: null,
            combo__c: null,
        },
        vrnA__MGA_Broker_Checkbox__c: false,
        retail_Broker__c: null,
        retail_Broker__r: null,
    },
    subjectivities__c: null,
    prior_Policy_Expiration_Date__c: '2022-03-22',
    vrnA__Expiration_Date__c: '2022-03-22',
    vrnA__Effective_Date__c: '2022-03-22',
    evolve_Prior_Policy_Expiration_Date__c: 'string',
    vrnA__Broker__r: 'string',
    quote_Status: 'string',
    quote_limit: 123,
    quote_limit_type: 'string',
    quote_Deductible: 123,
    quote_Discount_Premium: 123,
    total_Payable: 'string',
    option_Deductible: 'string',
    quote_Duplicate: 'string',
    referral_Status__c: 'string',
    referral_Status: 'string'
};